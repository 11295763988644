import React from "react";
import {TableContainer, Paper, Table, TableRow, TableCell, Box, Typography, TableBody} from "@mui/material";
import {AdminCompetitionMeta} from "../../data-types";
import {TypographyInlineLinks} from "../../components";
import {DataFormatting} from "../../util";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import NotesIcon from '@mui/icons-material/Notes';
import PaidIcon from '@mui/icons-material/Paid';
import KeyIcon from '@mui/icons-material/Key';

interface ConfigurationTableProps {
    meta: AdminCompetitionMeta;
}

export function ConfigurationTable({meta}: ConfigurationTableProps) {

    return (
        <TableContainer component={Paper} elevation={1}>
            <Table aria-label="configuration-table">
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Box component="div" sx={{display: "flex", alignItems: "center", gap: 1}}>
                                <NotesIcon />
                                <Typography variant="subtitle2">
                                    Description
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2">
                                {meta.description || "None"}
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Box component="div" sx={{display: "flex", alignItems: "center", gap: 1}}>
                                <EmojiEventsIcon />
                                <Typography variant="subtitle2">
                                    Prize Description
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell sx={{whiteSpace: "pre-wrap"}}>
                            <TypographyInlineLinks
                                text={meta.prizeDescription || "None"}
                                variant="subtitle2"
                            />
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Box component="div" sx={{display: "flex", alignItems: "center", gap: 1}}>
                                <PaidIcon />
                                <Typography variant="subtitle2">
                                    Event Cost
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2">
                                {meta.paymentParticipant ? `$${(meta.paymentParticipant / 100).toFixed(2)}` : "Free"}
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Box component="div" sx={{display: "flex", alignItems: "center", gap: 1}}>
                                <KeyIcon />
                                <Typography variant="subtitle2">
                                    Access Level
                                </Typography>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Typography variant="subtitle2">
                                {DataFormatting.capitalizeFirstChar(meta.accessibility)}
                            </Typography>
                        </TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer>
    )
}
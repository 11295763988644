import React, {useState, useContext} from "react";
import {Container, Grid2 as Grid, Typography, Tabs, Tab, useMediaQuery} from "@mui/material";
import {LoadingSpinner, ErrorComponent, LegalAgreementModal, OptionalStepCompetitionModal} from "../../components";
import {OpenEnrollSection} from "./OpenEnrollSection";
import {InvitationSection} from "./InvitationSection";
import {GoToResponsesModal} from "./GoToResponsesModal";
import {useEnroll} from "./useEnroll";
import {toast} from "react-toastify";
import {formatError, DataValidation} from "../../util";
import {UserContext} from "../../context";
import {OptionalScriptStepConfig, UserProfileState} from "../../data-types";
import {parseISO, formatDistanceToNow, subHours, isAfter} from "date-fns";
import {ProfileEditModal} from "./ProfileEditModal";

type EnrollState = "start" | "legal" | "institutionSelect" | "profileEdit";

export function Enroll() {

    const {user, setUser} = useContext(UserContext);
    const {
        requestStatus,
        invitations,
        openCompetitions,
        enrollTab,
        handleTabChange,
        handleEnroll,
        rejectInvitation
    } = useEnroll();

    const isMobile = useMediaQuery('(max-width: 600px)');

    // for handling enrollment flow
    const [enrollState, setEnrollState] = useState<EnrollState>("start");

    const [selectedCompetition, setSelectedCompetition] = useState<number>(NaN);

    const [legalAgreementUuid, setLegalAgreementUuid] = useState<string | null>(null);

    const [selectedInstitution, setSelectedInstitution] = useState<number | null>(NaN);
    const [optionalScriptStepOptions, setOptionScriptStepOptions] = useState<OptionalScriptStepConfig[]>([]);

    const countInvitations = invitations.current.length;
    const countAvailable = openCompetitions.length;

    const [goToResponsesCompetitionId, setGoToResponsesCompetitionId] = useState<number | null>(null);

    const handleInstitutionSelect = (id: number | null) => {
        setSelectedInstitution(id);
    }

    const handleCancel = () => {
        setSelectedCompetition(NaN);
        setSelectedInstitution(NaN);
        setLegalAgreementUuid(null);
        setOptionScriptStepOptions([]);
        setEnrollState("start");
    }

    const handleCloseGoToResponses = () => {
        setGoToResponsesCompetitionId(null);
    }

    const handleSaveProfile = (u: UserProfileState) => {
        setUser(u);
        const found = [...invitations.current, ...openCompetitions].find(c => c.competitionId === selectedCompetition)!;
        const foundPayment = found.paymentParticipant;

        // event found, does it have a legal agreement?
        if (found.legalAgreementUuid) {
            // yes, open the legal modal
            setEnrollState("legal");
            setLegalAgreementUuid(found.legalAgreementUuid);
            setSelectedCompetition(selectedCompetition);
        } else if (found.isSingleInstSelection) {
            // open the optional step modal
            setEnrollState("institutionSelect");
            setOptionScriptStepOptions(found.optionalScriptSteps);
            setSelectedCompetition(selectedCompetition);
        } else {
            // neither legal agreement, nor single inst.  Attempt submission
            handleEnroll(selectedCompetition, selectedInstitution)
                .then(_ => {
                    if (foundPayment === 0) {
                        // only toast for users that weren't redirected to payment
                        toast.success(`You have enrolled in the event.`)
                        // only present modal for users that weren't redirected to payment
                        // AND for events where round has started
                        if (found.firstRoundStart && isAfter(new Date(), parseISO(found.firstRoundStart + "Z"))) {
                            setGoToResponsesCompetitionId(selectedCompetition);
                        }
                    }
                })
                .catch(e => {
                    toast.error(formatError(e));
                })
                .finally(handleCancel);
        }
    }

    const initEnroll = (competitionId: number) => {
        const found = [...invitations.current, ...openCompetitions].find(c => c.competitionId === competitionId);
        if (!found) {
            toast.error("There was a problem, please try again later.");
            return;
        }

        // if payment is non-zero, does response phase end within 3 hours?  If so, disallow enrollment
        const foundPayment = found.paymentParticipant;
        const firstRoundEnd = found.firstRoundResponseEnd;
        if (foundPayment > 0 && firstRoundEnd !== null) {
            const firstRoundEndDate = parseISO(firstRoundEnd + "Z");
            if (isAfter(new Date(), subHours(firstRoundEndDate, 3))) {
                toast.error("You cannot enroll in this event within 3 hours of responses being due.");
                return;
            } else if (isAfter(new Date(), subHours(firstRoundEndDate, 24))) {
                toast.warning(`Please note:  Responses are due in ${formatDistanceToNow(firstRoundEndDate)}.  You will be ineligible for a refund if you enroll.`, {position: isMobile ? "top-left" : "bottom-left"});
            }
        }

        // first check if it requires profile and if profile complete
        if (found.requiresProfile && user && DataValidation.isUserProfileIncomplete(user)) {
            setEnrollState("profileEdit");
            setSelectedCompetition(competitionId);
            return;
        }

        // event found, does it have a legal agreement?
        if (found.legalAgreementUuid) {
            // yes, open the legal modal
            setEnrollState("legal");
            setLegalAgreementUuid(found.legalAgreementUuid);
            setSelectedCompetition(competitionId);
        } else if (found.isSingleInstSelection) {
            // open the optional step modal
            setEnrollState("institutionSelect");
            setOptionScriptStepOptions(found.optionalScriptSteps);
            setSelectedCompetition(competitionId);
        } else {
            // neither legal agreement, nor single inst.  Attempt submission

            handleEnroll(competitionId, selectedInstitution)
                .then(_ => {
                    if (foundPayment === 0) {
                        // only toast for users that weren't redirected to payment
                        toast.success(`You have enrolled in the event.`)
                        // only present modal for users that weren't redirected to payment
                        // AND for events where round has started
                        if (found.firstRoundStart && isAfter(new Date(), parseISO(found.firstRoundStart + "Z"))) {
                            setGoToResponsesCompetitionId(competitionId);
                        }
                    }
                })
                .catch(e => {
                    toast.error(formatError(e));
                });
        }
    }

    const legalTransitionHandler = () => {
        const found = [...invitations.current, ...openCompetitions].find(c => c.competitionId === selectedCompetition)!;
        const foundPayment = found.paymentParticipant;
        // does this event have single inst selection?
        if (found.isSingleInstSelection) {
            setOptionScriptStepOptions(found.optionalScriptSteps);
            setEnrollState("institutionSelect");
        } else {
            handleEnroll(selectedCompetition, selectedInstitution)
                .then(_ => {
                    if (foundPayment === 0) {
                        toast.success(`You have enrolled in the event.`);
                    }
                    // only present modal for users that weren't redirected to payment
                    // AND for events where round has started
                    if (found.firstRoundStart && isAfter(new Date(), parseISO(found.firstRoundStart + "Z"))) {
                        setGoToResponsesCompetitionId(selectedCompetition);
                    }
                })
                .catch(e => {
                    toast.error(formatError(e));
                })
                .finally(handleCancel)
        }
    }

    const optionalStepTransitionHandler = () => {
        const found = [...invitations.current, ...openCompetitions].find(c => c.competitionId === selectedCompetition)!;
        const foundPayment = found.paymentParticipant;
        handleEnroll(selectedCompetition, selectedInstitution)
            .then(_ => {
                if (foundPayment === 0) {
                    toast.success(`You have enrolled in the event.`);
                }
                // only present modal for users that weren't redirected to payment
                // AND for events where round has started
                if (found.firstRoundStart && isAfter(new Date(), parseISO(found.firstRoundStart + "Z"))) {
                    setGoToResponsesCompetitionId(selectedCompetition);
                }
            })
            .catch(e => {
                toast.error(formatError(e));
            })
            .finally(handleCancel)
    }

    if (requestStatus === "loading") {
        return <LoadingSpinner />;
    } else if (requestStatus === "error") {
        return <ErrorComponent />;
    } else {
        return (
            <Container sx={{mb: 10}}>
                <Grid container spacing={2}>
                    <Grid size={{xs: 12}}>
                        <Typography variant="h4">
                            Enroll Events
                        </Typography>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Tabs
                            value={enrollTab}
                            centered
                            onChange={handleTabChange}
                            textColor="inherit"
                        >
                            <Tab label={`Invitations ${countInvitations > 0 ? `(${countInvitations})` : ""}`} value="invitations" />
                            <Tab label={`Available ${countAvailable > 0 ? `(${countAvailable})` : ""}`} value="available" />
                        </Tabs>
                    </Grid>
                    <Grid size={{xs: 12}}>
                        {enrollTab === "available" ?
                            <OpenEnrollSection
                                openCompetitions={openCompetitions}
                                initEnroll={initEnroll}
                            /> :
                            <InvitationSection
                                invitations={invitations}
                                initAccept={initEnroll}
                                reject={rejectInvitation}
                            />
                        }
                    </Grid>
                </Grid>
                <LegalAgreementModal
                    open={enrollState === "legal"}
                    confirmAction={legalTransitionHandler}
                    legalAgreementUuid={legalAgreementUuid}
                    handleCancel={handleCancel}
                />
                <OptionalStepCompetitionModal
                    open={enrollState === "institutionSelect"}
                    confirmAction={optionalStepTransitionHandler}
                    optionalScriptSteps={optionalScriptStepOptions}
                    selected={selectedInstitution}
                    handleInstitutionSelect={handleInstitutionSelect}
                    handleCancel={handleCancel}
                />
                <GoToResponsesModal
                    competitionId={goToResponsesCompetitionId}
                    close={handleCloseGoToResponses}
                />
                <ProfileEditModal
                    open={enrollState === "profileEdit"}
                    onClose={handleCancel}
                    user={user!}
                    handleCancel={handleCancel}
                    handleSave={handleSaveProfile}
                />
            </Container>
        )
    }
}
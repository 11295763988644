import React, {PropsWithChildren, forwardRef} from "react";
import {Box, SxProps} from "@mui/material";

interface ModalBoxProps {
    sxOverrides?: SxProps;
}

type ModalBoxPropsWithChildren = PropsWithChildren<ModalBoxProps>;

const sx: SxProps = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {xs: "100%", sm: "100%", md: "50%"},
    maxHeight: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto'
}

export const ModalBox = forwardRef((props: ModalBoxPropsWithChildren, ref) =>
    <Box
        component="div"
        tabIndex={0}
        sx={{...sx, ...props.sxOverrides}}
        ref={ref}
    >
        {props.children}
    </Box>
)
import {useTimer} from "../index";
import {useState, useCallback, useRef, useEffect} from "react";
import {DataFormatting} from "../../util";

export const useRecord = (duration?: number) => {
    // TIMER
    const {time, resetTimer, stopTimer, startTimer} = useTimer(duration);

    // is it recording?
    const [isRecording, setIsRecording] = useState<boolean>(false);
    const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false);

    // DATA
    const recordingData = useRef<Blob[]>([]);

    // RECORD OBJECT -- RECREATED ON EACH 'START_RECORDING'
    const recorderRef = useRef<MediaRecorder | null>(null);

    const startRecording = useCallback((stream: MediaStream) => {
        // get the supported mimetype
        const mimeType = DataFormatting.getRecordingMimeType();
        // init the recorder object.  we use 512kb for video and 128kb for audio
        // without specifying video bitrate, default is 2.56mb so this represents an
        // 80% reduction from default.
        recorderRef.current = new MediaRecorder(stream, {
            mimeType: mimeType,
            videoBitsPerSecond: 512000,
            audioBitsPerSecond: 128000
        });
        // set up events for the recorder
        recorderRef.current.ondataavailable = (event: BlobEvent) => {
            if (event.data.size > 0) {
                recordingData.current.push(event.data);
            }
        }
        recorderRef.current.onstop = () => {
            setIsRecording(false);
        }
        // should cause a data event every 500 milliseconds
        recorderRef.current.start(500);
        startTimer();
        setIsRecording(true);
    }, [startTimer]);

    const stopRecording = useCallback(() => {
        recorderRef.current!.stop();
        stopTimer();
        setIsDataAvailable(true);
    }, [stopTimer]);

    const resetRecording = useCallback(() => {
        recordingData.current = [];
        resetTimer();
        setIsDataAvailable(false);
    }, [resetTimer]);

    useEffect(() => {
        if (duration && time === 0) {
            stopRecording()
        }
    }, [time, duration, stopRecording]);

    return {
        time: time,
        startRecording: startRecording,
        stopRecording: stopRecording,
        resetRecording: resetRecording,
        isRecording: isRecording,
        isDataAvailable: isDataAvailable,
        recordingData: recordingData
    }
}
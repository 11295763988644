import React, {useContext, useEffect, useState} from "react";
import {Divider, MenuItem, TextField, Paper, Avatar, Button, Container, Typography, Grid2 as Grid, ClickAwayListener, Tooltip, IconButton} from "@mui/material";
import {PasswordReset, TutorialModal} from "../../components";
import {useAvatarUpload, useDeactivateAccount, useProfileEdit} from "../../hooks";
import {AgeOptions, CountryOptions, LanguageOptions, formatError, DataValidation} from "../../util";
import {UserContext} from "../../context";
import {toast} from "react-toastify";
import {useSearchParams, useNavigate} from "react-router-dom";
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {DeactivateAccountModal} from "./DeactivateAccountModal";
import EnrollTutorialVideo from "../../video/enroll_tutorial.mp4";

export function UserProfile() {
    const {user, setUser} = useContext(UserContext);
    const [searchParams] = useSearchParams();
    const {avatarUrl, handleAvatarChange} = useAvatarUpload(`/api/avatar/createUserAvatar.json`, "user");
    const {editedProfileState, handleChange, submitProfileEdits, isModified} = useProfileEdit(user!);
    const {handleDeactivation} = useDeactivateAccount();
    const [tutorialModalOpen, setTutorialModalOpen] = useState<boolean>(false);
    const [deactivateModalOpen, setDeactivateModalOpen] = useState<boolean>(false);
    const [demographicsTooltipOpen, setDemographicsTooltipOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        let src = searchParams.get("src")
        if (src === "login") {
            setTutorialModalOpen(true);
        }
    }, [searchParams]) ;

    const submit = () => {
        submitProfileEdits()
            .then(_ => {
                setUser(editedProfileState);
                toast.success("Profile updated successfully")
            })
            .catch(e => {
                toast.error(formatError(e));
            })
    }

    const deactivate = () => {
        handleDeactivation()
            .then(_ => {
                toast.success("Account Successfully archived");
                setUser(null);
            })
            .catch(e => {
                toast.error(e);
            })
    }

    const tooltipClose = () => {
        setDemographicsTooltipOpen(false);
    }

    const tooltipOpen = () => {
        setDemographicsTooltipOpen(true);
    }

    const avatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleAvatarChange(e)
            .then(_ => navigate(0));
    }

    if (!user) {
        return <Container />
    } else {
        const profileIncomplete = DataValidation.isUserProfileIncomplete(user);
        const tooltipText = profileIncomplete ?
            <>
                <Typography variant="subtitle2" sx={{mb: 2}}>
                    Some events require you to fill out the demographic section to be eligible to be compete.
                </Typography>
                <Typography variant="subtitle2" sx={{mb: 2}}>
                    We encourage you to fill out these fields completely to have the best chance of joining each event.
                </Typography>
                <Typography variant="subtitle2">
                    If you don't use a Secondary Language, feel free to select your Primary Language for your Secondary Language.
                </Typography>
            </> :
            <>
                <Typography variant="subtitle2">Profile complete, thank you!</Typography>
            </>;

        return (
            <Container sx={{mb: 10}}>
                <Grid container sx={{mb: 2}} alignItems="center" justifyContent="space-between">
                    <Grid>
                        <Typography variant="h4">
                            User Profile
                        </Typography>
                    </Grid>
                    <Grid>
                        <Button
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={() => setTutorialModalOpen(true)}
                            startIcon={<PlayCircleIcon />}
                        >
                            Tutorial
                        </Button>
                    </Grid>
                </Grid>
                <Paper sx={{mt: 5, mb: 5, p: 4}}>
                    <Grid container spacing={2} justifyContent="align" alignItems="center">
                        <Grid size={{xs: 12}}>
                            <Typography variant="h4">
                                General
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12, sm: 6}}>
                            <Typography variant="h6">
                                {`${user.firstName} ${user.lastName}`}
                            </Typography>
                            <Typography variant="body1">
                                {user.username}
                            </Typography>
                            <Typography variant="body1">
                                {user.email}
                            </Typography>
                        </Grid>
                        <Grid container size={{xs: 12, sm: 6}} direction="column" justifyContent="align" alignItems="center">
                            <Avatar
                                src={avatarUrl !== "" ? avatarUrl : user.avatar ? `data:image/jpeg;base64,${user.avatar}` : ""}
                                sx={{
                                    height: 125,
                                    width: 125,
                                    mb: 3
                                }}
                                imgProps={{
                                    alt: "user avatar"
                                }}
                            />
                            <Button
                                variant="contained"
                                component="label"
                                color="primary"
                                size="small"
                            >
                                Click to Change your Avatar
                                <input
                                    style={{display: 'none'}}
                                    accept="image/*"
                                    type="file"
                                    id="user-avatar"
                                    onChange={avatarChange}
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper sx={{mt: 5, mb: 5, p: 4}}>
                    <Grid container spacing={2} justifyContent="align" alignItems="center" rowSpacing={6}>
                        <Grid container size={{xs: 12}} alignItems="center" justifyContent="space-between">
                            <Grid>
                                <Typography variant="h4">
                                    Demographics
                                </Typography>
                            </Grid>
                            <Grid>
                                <ClickAwayListener onClickAway={tooltipClose}>
                                    <Tooltip
                                        PopperProps={{disablePortal: true}}
                                        open={demographicsTooltipOpen}
                                        onClose={tooltipClose}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                        placement="bottom-start"
                                        title={tooltipText}
                                    >
                                        <IconButton onClick={tooltipOpen}>
                                            {profileIncomplete ?
                                                <ErrorIcon color="secondary" /> :
                                                <CheckCircleIcon color="primary" />
                                            }
                                        </IconButton>
                                    </Tooltip>
                                </ClickAwayListener>
                            </Grid>
                        </Grid>
                        <Grid size={{xs: 12, sm: 12, md: 6}}>
                            <Typography variant="h6" sx={{mb: 2}}>
                                Age Bracket
                            </Typography>
                            <TextField
                                select
                                required
                                label="Age Bracket"
                                name="ageBracket"
                                id="ageBracket"
                                sx={{width: {md: 250, xs: "100%"}, mb: 2}}
                                onChange={handleChange}
                                value={editedProfileState.ageBracket ?? -1}
                            >
                                {AgeOptions.map((c, i) => (
                                    <MenuItem
                                        key={i}
                                        value={c.value}
                                        disabled={c.value === -1}
                                    >
                                        {c.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Typography variant="subtitle2">
                                {`Current:  ${AgeOptions.find(s => s.value === user.ageBracket)?.label ?? "Not Specified"}`}
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12, sm: 12, md: 6}}>
                            <Typography variant="h6" sx={{mb: 2}}>
                                Country
                            </Typography>
                            <TextField
                                select
                                required
                                label="Country"
                                id="regionOrCountry"
                                name="regionOrCountry"
                                sx={{width: {md: 250, xs: "100%"}, mb: 2}}
                                onChange={handleChange}
                                value={editedProfileState.regionOrCountry ?? "Not Specified"}
                            >
                                {CountryOptions.map((c, i) => (
                                    <MenuItem
                                        key={i}
                                        value={c.value}
                                        disabled={c.value === "Not Specified"}
                                    >
                                        {c.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Typography variant="subtitle2">
                                {`Current:  ${CountryOptions.find(s => s.value === user.regionOrCountry)?.label ?? "Not Specified"}`}
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12, sm: 12, md: 6}}>
                            <Typography variant="h6" sx={{mb: 2}}>
                                Primary Language
                            </Typography>
                            <TextField
                                select
                                required
                                id="primaryLanguage"
                                label="Primary Language"
                                name="primaryLanguage"
                                sx={{width: {md: 250, xs: "100%"}, mb: 2}}
                                onChange={handleChange}
                                value={editedProfileState.primaryLanguage ?? "Not Specified"}
                            >
                                {LanguageOptions.map((c, i) => (
                                    <MenuItem
                                        key={i}
                                        value={c.value}
                                        disabled={c.value === "Not Specified"}
                                    >
                                        {c.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Typography variant="subtitle2">
                                {`Current:  ${LanguageOptions.find(s => s.value === user.primaryLanguage)?.label ?? "Not Specified"}`}
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12, sm: 12, md: 6}}>
                            <Typography variant="h6" sx={{mb: 2}}>
                                Secondary Language
                            </Typography>
                            <TextField
                                select
                                id="secondaryLanguage"
                                label="Secondary Language"
                                name="secondaryLanguage"
                                sx={{width: {md: 250, xs: "100%"}, mb: 2}}
                                onChange={handleChange}
                                value={editedProfileState.secondaryLanguage ?? "Not Specified"}
                            >
                                {LanguageOptions.map((c, i) => (
                                    <MenuItem
                                        key={i}
                                        value={c.value}
                                        disabled={c.value === "Not Specified"}
                                    >
                                        {c.label}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Typography variant="subtitle2">
                                {`Current:  ${LanguageOptions.find(s => s.value === user.secondaryLanguage)?.label ?? "Not Specified"}`}
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12, sm: 12, md: 6}}>
                            <Typography variant="h6" sx={{mb: 2}}>
                                High School Postal Code
                            </Typography>
                            <TextField
                                required
                                id="highSchoolZip"
                                disabled={!(editedProfileState.regionOrCountry)}
                                label="High School Postal Code"
                                name="highSchoolZip"
                                sx={{width: {md: 250, xs: "100%"}, mb: 2}}
                                onChange={handleChange}
                                value={editedProfileState.highSchoolZip ?? ""}
                                slotProps={{
                                    htmlInput: {maxLength: 10}
                                }}
                            />
                            {editedProfileState.regionOrCountry === "US" ?
                                <Typography variant="subtitle2">
                                    Format:  12345 or 12345-6789
                                </Typography> :
                                null
                            }
                            <Typography variant="subtitle2">
                                {`Current:  ${user.highSchoolZip || "Not Specified"}`}
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12, sm: 12, md: 6}}>
                            <Typography variant="h6" sx={{mb: 2}}>
                                Home Postal Code
                            </Typography>
                            <TextField
                                id="mailingZip"
                                required
                                disabled={!(editedProfileState.regionOrCountry)}
                                label="Home Postal Code"
                                name="mailingZip"
                                sx={{width: {md: 250, xs: "100%"}, mb: 2}}
                                onChange={handleChange}
                                value={editedProfileState.mailingZip ?? ""}
                                slotProps={{
                                    htmlInput: {maxLength: 10}
                                }}
                            />
                            {editedProfileState.regionOrCountry === "US" ?
                                <Typography variant="subtitle2">
                                    Format:  12345 or 12345-6789
                                </Typography> :
                                null
                            }
                            <Typography variant="subtitle2">
                                {`Current:  ${user.mailingZip || "Not Specified"}`}
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12, sm: 12, md: 6}}>
                            <Typography variant="h6" sx={{mb: 2}}>
                                US Citizen or Permanent Resident
                            </Typography>
                            <TextField
                                select
                                required
                                id="usResident"
                                label="Us Resident"
                                name="usResident"
                                sx={{width: {md: 250, xs: "100%"}, mb: 2}}
                                onChange={handleChange}
                                value={
                                    (editedProfileState.usResident === undefined ||
                                        editedProfileState.usResident === null) ?
                                        "Not Specified" :
                                        (editedProfileState.usResident) ?
                                            1 :
                                            0
                                }
                            >
                                <MenuItem
                                    value={"Not Specified"}
                                    disabled
                                >
                                    Not Specified
                                </MenuItem>
                                <MenuItem
                                    value={1}
                                >
                                    Yes
                                </MenuItem>
                                <MenuItem
                                    value={0}
                                >
                                    No
                                </MenuItem>
                            </TextField>
                            <Typography variant="subtitle2">
                                {`Current:  ${(user.usResident === undefined ||
                                        user.usResident === null) ?
                                        "Not Specified" :
                                        (user.usResident) ?
                                            "Yes" :
                                            "No"}`}
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12}} sx={{mt: 3}}>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={!isModified}
                                onClick={submit}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper sx={{mt: 5, mb: 5, p: 4}}>
                    <PasswordReset />
                </Paper>
                <Paper sx={{mt: 5, mb: 5, p: 4}}>
                    <Grid container>
                        <Grid size={{xs: 12}}>
                            <Typography variant="h6">
                                Deactivate Your Account
                            </Typography>
                        </Grid>
                        <Grid size={{xs: 12}}>
                            <Divider sx={{my: 2}} />
                        </Grid>
                        <Grid container spacing={2} size={{xs: 12}} justifyContent="center" alignItems="center">
                            <Grid size={{xs: 12, sm: 12, md: 6}}>
                                <Typography variant="subtitle2">
                                    Deactivating your account will log you out and lock your account from further logins.  Furthermore, deactivating your account will disqualify you from any current events that you are enrolled in.
                                </Typography>
                            </Grid>
                            <Grid size={{xs: 12, sm: 12, md: 6}} sx={{textAlign: "center"}}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    startIcon={<ErrorIcon />}
                                    onClick={() => setDeactivateModalOpen(true)}
                                >
                                    Deactivate Account
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <TutorialModal
                    open={tutorialModalOpen}
                    setOpen={setTutorialModalOpen}
                    video={EnrollTutorialVideo}
                    title="Welcome to FocusRing!"
                >
                    <Typography variant="subtitle2" sx={{mb: 2}}>
                        Now that you’ve registered, please take these few steps to get started:
                    </Typography>
                    <Typography variant="subtitle2">
                        1). Watch this brief tutorial video on how to enroll in a FocusRing event.
                    </Typography>
                    <Typography variant="subtitle2">
                        2). After the video, click the “Close” button.
                    </Typography>
                    <Typography variant="subtitle2">
                        3). Be sure to complete the brief Demographics section that appears after you click “Close”.
                    </Typography>
                    <Typography variant="body1" sx={{mt: 2, fontWeight: "bold"}}>
                        Good luck!
                    </Typography>
                </TutorialModal>
                <DeactivateAccountModal
                    open={deactivateModalOpen}
                    setOpen={setDeactivateModalOpen}
                    action={deactivate}
                />
            </Container>
        )
    }
}
